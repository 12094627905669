import { Box } from "@chakra-ui/react";
import React, { memo } from "react";
import Container from "../Container/Container";
import { cdnLink } from "../ShopFeedback/ShopFeedback";
import SignupForm from "./SignupForm";
// import SignupForm from '../components/SignupForm'

const SignupUseFree = (props) => {
  return (
    <Box
      bgImage={`${cdnLink}/2022/04/14102556/PAGE7.svg`}
      bgRepeat="no-repeat"
      bgPosition="center center"
      bgSize="cover"
      py="3rem"
    >
      <Container>
        <Box
          as="h2"
          textStyle="titleSection"
          color="primary"
          textAlign="center"
        >
          sign-up-for-a-free-trial
          and-receive-the-livestream-manual-to-sell-thousands-of-orders!
        </Box>

        <Box
          pt={{ base: "30px", md: "61px" }}
          display="flex"
          justifyContent="center"
        >
          <SignupForm />
        </Box>
      </Container>
    </Box>
  );
};

export default memo(SignupUseFree);
