import { Box } from "@chakra-ui/react";
import React, { useRef } from "react";
import Seo from "components/seo";
import { FooterLanding, HeaderLanding } from "../components/Landing";
import {
  Hero,
  ProblemShop,
  Customer,
  Giveaway,
  Solution,
  TypicalCustomer,
} from "../components/KaioSeeding";
import { graphql } from "gatsby";
import { get } from "lodash";

export default function Landing({ data, pageContext }) {
  const ref = useRef(null);
  const scrollToSection = () => ref.current?.scrollIntoView();

  const headerData = get(data, "strapiPage.header", {});
  const footerData = get(data, "strapiPage.footer", {});

  const renderDynamicZone = (item) => {
    switch (item.__typename) {
      case "STRAPI__COMPONENT_SECTIONS_SEEDING_BANNER":
        return (
          <Box ref={ref} key={item.id}>
            <Hero data={item} />
          </Box>
        );
      case "STRAPI__COMPONENT_SECTIONS_ISSUE":
        return <ProblemShop data={item} />;
      case "STRAPI__COMPONENT_SECTIONS_IMPACTS":
        return <Solution data={item} />;

      case "STRAPI__COMPONENT_SECTIONS_CUSTOMERS":
        return (
          <>
            <Customer data={item} />
            <TypicalCustomer data={item} />
          </>
        );

      case "STRAPI__COMPONENT_SECTIONS_SALES":
        return <Giveaway scroll={scrollToSection} data={item} />;
      default:
        return <div></div>;
    }
  };
  return (
    <Box>
      <Seo
        title={get(data, "strapiPage.seo.title", "")}
        description={get(data, "strapiPage.seo.description", "")}
        ogImage={get(data, "strapiPage.seo.shareImage.url", "")}
        keywords={get(data, "strapiPage.seo.keyworks", [])}
        author={get(data, "strapiPage.seo.author", "")}
        supportedLanguages={get(
          data,
          "site.siteMetadata.supportedLanguages",
          []
        )}
        host={get(data, "site.siteMetadata.siteUrl", "")}
      />
      <HeaderLanding data={headerData} />
      <Box minH="100vh">{data?.strapiPage?.body?.map(renderDynamicZone)}</Box>
      <FooterLanding data={footerData} />
    </Box>
  );
}

export const query = graphql`
  query LandingPage($slug: String) {
    site {
      siteMetadata {
        title
        description
        supportedLanguages
        siteUrl
      }
    }
    strapiPage(slug: { eq: $slug }) {
      seo {
        author
        description
        title
        shareImage {
          url
        }
      }
      header {
        navbar {
          url
          name
          target
        }
        logo {
          alt
          image {
            url
          }
        }
      }
      footer {
        contactInfo {
          phone
          title
          email
          address
          office
          businessCode
        }
        copyright
        nav {
          title
          link
        }
      }
      body {
        ...SEEDING_BANNER
        ...ISSUE_SECTION
        ...SOLUTION_SECTION
        ...GIVEAWAY_SECTION
        ...CUSTOMER_SECTION
      }
    }
  }
`;
