import React, { memo } from "react";
import {
  Flex,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
  Image,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link } from "gatsby";
import Container from "../Container/Container";
import { get } from "lodash";

function HeaderLanding({ data }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Box position="sticky" top={0} width="full" bgColor="#fff" zIndex="header">
      <Container>
        <Flex justifyContent="space-between" alignItems="center" py="1.25rem">
          <Link to="/">
            <Flex alignItems="center" cursor="pointer">
              <Image
                src={get(data, "logo.image.url", "")}
                width="4rem"
                alt={get(data, "logo.alt", "")}
                priority={"true"}
              />
            </Flex>
          </Link>

          <IconButton
            display={{
              base: "block",
              lg: "none",
            }}
            icon={<HamburgerIcon />}
            aria-label="Hamburger button"
            onClick={onOpen}
          />

          <Flex
            alignItems="center"
            display={{
              base: "none",
              lg: "inherit",
            }}
          >
            <Box as="nav">
              <Box as="ul">
                {get(data, "navbar", [])?.map((link) => (
                  <Box
                    key={link.url}
                    as="li"
                    px="1rem"
                    display="inline-block"
                    textStyle="nav"
                  >
                    {link.target === "_blank" ? (
                      <Box
                        as="a"
                        href={link.url}
                        color="primary"
                        target="_blank"
                        _hover={{
                          textDecoration: "none",
                        }}
                      >
                        {link.name}
                      </Box>
                    ) : (
                      <ChakraLink
                        color="primary"
                        hover={{ textDecoration: "none" }}
                        as={Link}
                        to={link.url}
                        fontSize={15}
                        fontWeight={500}
                      >
                        {link.name}
                      </ChakraLink>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
            {/* <LanguageMenu /> */}
          </Flex>
        </Flex>

        <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay>
            <DrawerContent height="100vh" bgColor="rgba(0,0,0,0.8)">
              <DrawerHeader p={0}>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  py="1.25rem"
                  px="3rem"
                  bgColor="primary"
                >
                  <Link to="/">
                    <Image
                      src={get(data, "logo.image.url", "")}
                      width="4rem"
                      alt={get(data, "logo.alt", "")}
                      priority={"true"}
                    />
                  </Link>

                  <IconButton
                    icon={<CloseIcon />}
                    aria-label="Hamburger close button"
                    onClick={onClose}
                  />
                </Flex>
              </DrawerHeader>
              <DrawerBody height="100vh">
                <Box as="nav">
                  <Box as="ul" pb="1rem">
                    {get(data, "navbar", [])?.map((link) => (
                      <Box
                        key={link.url}
                        as="li"
                        display="block"
                        py="1rem"
                        borderBottom="1px solid #ccc"
                        textStyle="nav"
                      >
                        {link.target === "_blank" ? (
                          <Box as="a" href={link.url} target="_blank">
                            {link.name}
                          </Box>
                        ) : (
                          <ChakraLink
                            color="primary"
                            hover={{}}
                            as={Link}
                            to={link.url}
                            fontSize={15}
                            fontWeight={500}
                          >
                            {link.name}
                          </ChakraLink>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Container>
    </Box>
  );
}

export default memo(HeaderLanding);
