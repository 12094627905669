import { Box, Divider, Flex, Text, Link } from "@chakra-ui/react";
import { get } from "lodash";
import React, { memo } from "react";
import Container from "../Container/Container";
import InfoCompany from "../InfoCompany/InfoCompany";
import { Link as GatsbyLink } from "gatsby";

function FooterLanding({ data }) {
  return (
    <Box bgColor="#F3F4F6" pb="2rem">
      <InfoCompany contact={get(data, "contactInfo", {})} />

      <Divider />
      <Container>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          color="#4B5563"
          pt="2rem"
        >
          <Text>{get(data, "copyright", "")}</Text>
          <Flex>
            {get(data, "nav", [])?.map((link, index) => (
              <Link as={GatsbyLink} to={`${link.linl}`} key={index.toString()}>
                <Text
                  ml="1rem"
                  fontWeight="500"
                  _hover={{
                    color: "primary",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {link.title}
                </Text>
              </Link>
            ))}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}

export default memo(FooterLanding);
