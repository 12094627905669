import React, { memo } from "react";
import { Container as ChakraContainer } from "@chakra-ui/react";

const Container = ({ children, ...containerProps }) => {
  return (
    <ChakraContainer
      maxWidth="1366px"
      px={{
        base: "32px",
        lg: "96px",
      }}
      {...containerProps}
    >
      {children}
    </ChakraContainer>
  );
};

export default memo(Container);
