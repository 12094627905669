import { Box } from "@chakra-ui/react";
import React from "react";
import Container from "../Container/Container";

export default function InfoCompany({ contact }) {
  return (
    <Box
      bgImage={"/images/contact_bg.png"}
      bgRepeat="no-repeat"
      bgPosition="center center"
      bgSize="cover"
      textAlign="center"
      bgColor="#1F2937"
      color="white"
      py="64px"
    >
      <Container>
        <Box as="h3" textStyle="titleSection" mb="40px">
          {contact.title}
        </Box>
        <Box
          textStyle="text"
          maxWidth="768px"
          margin="0 auto"
          sx={{ "& > div": { mb: "24px" } }}
        >
          <Box>{contact.address}</Box>
          <Box>{contact?.office}</Box>
          <Box>{contact?.phone}</Box>
          <Box>{contact?.businessCode}</Box>
        </Box>
      </Container>
    </Box>
  );
}
