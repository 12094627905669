import { Box, Image } from "@chakra-ui/react";
import { graphql } from "gatsby";
import { get } from "lodash";
import React, { memo } from "react";
import { Container } from "../../Landing";

const Solution = ({ data }) => {
  return (
    <Box position="relative">
      <Box position="absolute" bottom="-30%" left="0" zIndex={-1}>
        <Image
          src={get(data, "background.url", "")}
          alt={get(data, "background.alternativeText", "")}
        />
      </Box>
      <Container>
        <Box display="flex" flexWrap="wrap">
          <Box w={{ base: "100%", sm: "55%" }}>
            <Box as="h2" textStyle="titleLandingPage">
              {data?.title}
            </Box>
            <Box
              as="h3"
              fontSize="28px"
              lineHeight="36px"
              fontWeight="700"
              my="24px"
            >
              {data?.description}
            </Box>
          </Box>

          <Box
            w={{ base: "100%", sm: "45%" }}
            bgImage={get(data, "card.background.url", "")}
            bgRepeat="none"
            bgPosition="center center"
            bgSize="cover"
            p={{ base: "20px", sm: "24px 55px 80px" }}
            borderRadius="4px"
          >
            <Box
              as="h3"
              fontSize="24px"
              lineHeight="32px"
              fontWeight="500"
              mb="16px"
            >
              {data?.card?.title}
            </Box>

            <Box
              as="ul"
              listStyleType="none"
              sx={{
                li: { p: "16px", bgColor: "white", mb: "16px" },
                p: {
                  fontSize: { base: "14px", sm: "18px" },
                  lineHeight: { base: "22px", sm: "26px" },
                  fontWeight: "400",
                },
              }}
            >
              {data?.card?.impact?.map((item, index) => (
                <Box as="li" key={item.id}>
                  <Box as="p">{item.content}</Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default memo(Solution);
export const query = graphql`
  fragment SOLUTION_SECTION on STRAPI__COMPONENT_SECTIONS_IMPACTS {
    __typename
    ... on STRAPI__COMPONENT_SECTIONS_IMPACTS {
      id
      background {
        url
        alternativeText
      }
      card {
        title
        background {
          alternativeText
          url
        }
        impact {
          id
          icon {
            url
          }
          content
        }
      }
      description
      title
    }
  }
`;
