import { Box, Image, Link } from "@chakra-ui/react";
import { chunk, get } from "lodash";
import React, { memo, useMemo } from "react";
import { Container } from "../../Landing";

const TypicalCustomer = ({ data }) => {
  const patrons = useMemo(() => {
    return chunk(data?.patrons, 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Container>
        <Box position="relative">
          <Box
            as="h2"
            textStyle="titleLandingPage"
            textAlign="center"
            display={{ base: "block", sm: "none" }}
          >
            {data?.description}
          </Box>
          <Box
            display={{ base: "none", sm: "flex" }}
            justifyContent="center"
            alignItems="center"
            width={{ base: "130px", sm: "226px", md: "323px" }}
            height={{ base: "130px", sm: "226px", md: "323px" }}
            borderRadius="50%"
            bgColor="#F3F4F6"
            p="35px"
            position="absolute"
            top="50%"
            left="50%"
            transform={`translate(-50%, -50%)`}
            zIndex="-1"
          >
            <Box
              as="h2"
              fontSize={{ base: "24px", sm: "34px", md: "44px" }}
              lineHeight={{ base: "34px", sm: "44px", md: "54px" }}
              fontWeight="600"
              textAlign="center"
            >
              {data?.description}
            </Box>
          </Box>
          {patrons?.map((item, index) => (
            <Box
              display="flex"
              justifyContent={
                index === patrons.length - 1 ? "center" : "space-between"
              }
              key={index.toString()}
            >
              {item?.map((it, idx) => (
                <Link to={it.url} key={idx.toString()} isExternal>
                  <Image
                    src={get(it, "icon.url", "")}
                    alt={get(it, "icon.alternativeText", "")}
                    objectFit="cover"
                  />
                </Link>
              ))}
            </Box>
          ))}
        </Box>
      </Container>
    </>
  );
};

export default memo(TypicalCustomer);
