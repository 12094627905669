import { Box, Image } from "@chakra-ui/react";
import React, { memo } from "react";
import { CheckIcon } from "@chakra-ui/icons";
import { Container } from "../../Landing";
import { graphql } from "gatsby";
import FormSignup from "../FormSignUp";
import { get } from "lodash";

const Hero = ({ data }) => {
  return (
    <Box position="relative" pt="50px" id="form-signup-use">
      <Box position="absolute" top="0" right="0" zIndex={-1}>
        <Image
          src={get(data, "background.url", "")}
          alt={get(data, "background.alternativeText", "")}
        />
      </Box>
      <Container>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box w={{ base: "100%", md: "60%" }}>
            <Box
              as="h2"
              fontSize={{ base: "36px", sm: "44px" }}
              lineHeight={{ base: "54px", sm: "60px" }}
              fontWeight="600"
            >
              {data?.title}
            </Box>

            <Box
              as="h3"
              fontSize={{ base: "24px", sm: "28px" }}
              lineHeight={{ base: "34px", sm: "36px" }}
              fontWeight="600"
              mt="24px"
            >
              {data?.description}
            </Box>

            <Box
              as="ul"
              listStyleType="none"
              mt="36px"
              sx={{
                li: { display: "flex", alignItems: "center", mb: "18px" },
                "li > p": {
                  ml: "22px",
                  fontSize: { base: "18px", sm: "24px" },
                  lineHeight: { base: "28px", sm: "32px" },
                  fontWeight: "400",
                },
              }}
            >
              {get(data, "content", [])?.map((item) => (
                <Box as="li" key={item?.id}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgColor="primary"
                    boxSize={{ base: "18px", sm: "30px" }}
                    borderRadius="50%"
                  >
                    <CheckIcon color="white" w={{ base: "18px", sm: "30px" }} />
                  </Box>
                  <Box as="p">{item.content}</Box>
                </Box>
              ))}
            </Box>
          </Box>

          <Box w={{ base: "100%", md: "40%" }} pl={{ base: "0", md: "40px" }}>
            <Box
              as="h3"
              fontSize="24px"
              lineHeight="30px"
              fontWeight="600"
              color="primary"
              mb="24px"
            >
              {data?.contact?.contactTitle}
              <span role="img" aria-label="rocket">
                🚀
              </span>
            </Box>
            <FormSignup
              field={get(data, "contact.field", [])}
              button={get(data, "contact.button")}
              footerText={data?.contact?.contactFooter}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="center" mt="32px">
          <Image
            src={get(data, "image.url", "")}
            alt={get(data, "image.alternativeText", "")}
            objectFit="cover"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default memo(Hero);
export const query = graphql`
  fragment SEEDING_BANNER on STRAPI__COMPONENT_SECTIONS_SEEDING_BANNER {
    __typename
    ... on STRAPI__COMPONENT_SECTIONS_SEEDING_BANNER {
      id
      content {
        content
        id
      }
      title
      description
      background {
        url
        alternativeText
      }
      image {
        alternativeText
        url
      }
      contact {
        contactTitle
        contactFooter
        button {
          name
        }
        field {
          id
          name
          placeholder
          require
          type
        }
      }
    }
  }
`;
