import { Box, Image } from "@chakra-ui/react";
import { graphql } from "gatsby";
import { get } from "lodash";
import React, { memo } from "react";
import { Container } from "../../Landing";

const ProblemShop = ({ data }) => {
  return (
    <Box py={{ base: "50px", sm: "100px" }}>
      <Container>
        <Box
          as="h2"
          textStyle="titleLandingPage"
          color="#DC2626"
          textAlign="center"
        >
          {data?.title}
        </Box>

        <Box
          as="ul"
          listStyleType="none"
          display="flex"
          justifyContent={{ base: "center", sm: "space-between" }}
          flexWrap="wrap"
          mt="56px"
          sx={{
            li: {
              width: { base: "100%", md: "33.3333%" },
              maxWidth: "270px",
              mb: { base: "34px", md: "0" },
            },
          }}
        >
          {data?.Issues?.map((item, index) => (
            <Box as="li" key={`${item?.id}_${index}`} position="relative">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding="20px"
                bgColor="#FEE2E2"
                w="64px"
                h="64px"
                borderRadius="4px"
              >
                <Image
                  objectFit="contain"
                  w="48px"
                  h="48px"
                  src={get(item, "icon.url", "")}
                  alt={get(item, "icon.alternativeText", "")}
                />
              </Box>

              <Box
                as="h3"
                fontSize="18px"
                lineHeight="26px"
                fontWeight="500"
                mt="29px"
              >
                {item?.title}
              </Box>
              <Box
                as="p"
                fontSize="18px"
                lineHeight="26px"
                fontWeight="500"
                color="#6B7280"
                mt="8px"
              >
                {item?.description}
              </Box>

              <Box
                as="p"
                position="absolute"
                bottom="0"
                right="0"
                fontSize="204px"
                lineHeight="204px"
                fontWeight="700"
                color="#E5E7EB"
                zIndex={-1}
              >
                {index + 1}
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default memo(ProblemShop);

export const query = graphql`
  fragment ISSUE_SECTION on STRAPI__COMPONENT_SECTIONS_ISSUE {
    __typename
    ... on STRAPI__COMPONENT_SECTIONS_ISSUE {
      id
      Issues {
        description
        icon {
          url
        }
        title
        id
      }
      title
    }
  }
`;
