import { Box, Button, Image } from "@chakra-ui/react";
import { graphql } from "gatsby";
import { get } from "lodash";
import React, { memo } from "react";
import { Container } from "../../Landing";

const Giveaway = ({ scroll, data }) => {
  return (
    <Box py={{ base: "50px", sm: "100px" }} position="relative">
      <Box position="absolute" top="-20%" right="0" zIndex={-1}>
        <Image
          src={get(data, "background.url", "")}
          alt={get(data, "background.alternativeText", "")}
        />
      </Box>
      <Container>
        <Box
          bgColor="#F0F7FF"
          borderRadius="20px"
          p={{ base: "20px", sm: "56px 40px" }}
        >
          <Box
            as="h2"
            fontSize={{ base: "24px", sm: "44px" }}
            lineHeight={{ base: "34px", sm: "54px" }}
            fontWeight="700"
            mb="34px"
            textAlign={{ base: "center", sm: "left" }}
          >
            {data?.main?.title}
          </Box>
          <Box
            as="ul"
            listStyleType="none"
            sx={{
              li: {
                display: "flex",
                alignItems: "center",
                mb: { base: "8px", sm: "0" },
              },
              "li > div": {
                w: { base: "10%", sm: "6%" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              "li > p": {
                fontSize: { base: "16px", sm: "24px" },
                lineHeight: { base: "24px", sm: "32px" },
                fontWeight: "400",
                ml: "19px",
                w: "90%",
              },
            }}
          >
            {data?.main?.main?.map((item) => (
              <Box as="li" key={item.id}>
                <Box>
                  <Image
                    src={get(item, "icon.url", "")}
                    alt={get(item, "item.alternativeText", "")}
                  />
                </Box>
                <Box as="p">{item.content}</Box>
              </Box>
            ))}
          </Box>

          <Button
            bgColor="primary"
            color="white"
            fontSize="16px"
            lineHeight="24px"
            fontWeight="600"
            p={{ base: "24px", sm: "14px 24px" }}
            mt="40px"
            onClick={scroll}
          >
            {get(data, "main.buttonLink.name", "")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default memo(Giveaway);
export const query = graphql`
  fragment GIVEAWAY_SECTION on STRAPI__COMPONENT_SECTIONS_SALES {
    __typename
    ... on STRAPI__COMPONENT_SECTIONS_SALES {
      id
      background {
        url
        alternativeText
      }
      main {
        title
        buttonLink {
          type
          url
          target
          name
        }
        id
        main {
          id
          icon {
            url
            alternativeText
          }
          content
        }
      }
    }
  }
`;
