import React, { memo } from "react";
import { Box, Image, Link } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";

function PostCard({ post, featureImage, author, category }) {
  return (
    <Box m={{ base: "16px 0", md: "32px 0 0 32px" }}>
      <Link as={GatsbyLink} to={`/${post.post_name}`}>
        <Box position="relative" height={{ base: "200px", md: "300px" }}>
          <Image
            src={featureImage.url}
            alt={featureImage?.alt || "kaio posts"}
            w="100%"
            h="100%"
            objectFit="cover"
            borderRadius="8px"
            height="300px"
          />
          <Box
            position="absolute"
            left="0"
            bottom="0"
            color="#fff"
            w="100%"
            p={{ base: "12px 16px", md: "12px 54px" }}
            borderRadius="8px"
            zIndex="1"
          >
            <Box
              as="h4"
              fontSize={{ base: "10px", md: "14px" }}
              lineHeight="17px"
              fontWeight="700"
              textTransform={"uppercase"}
            >
              {category.name}
            </Box>
            <Box h="0.5px" w="30%" bgColor="#fff" mt="3px"></Box>
            <Box
              as="h3"
              fontSize={{ base: "12px", md: "20px" }}
              lineHeight={{ base: "15px", md: "23px" }}
              fontWeight="700"
              mt="10px"
            >
              {post.post_title}
            </Box>
            <Box
              as="p"
              fontSize={{ base: "8px", md: "10px" }}
              lineHeight={{ base: "9px", md: "12px" }}
              fontWeight="500"
              mt="5px"
            >
              Được viết bởi {author.data.display_name} vào ngày {post.date}
            </Box>
          </Box>

          <Box
            position="absolute"
            bottom="0"
            left="0"
            w="100%"
            h="100%"
            bgGradient="linear(rgba(109, 145, 245, 0.17) 35.32%, rgba(37, 99, 235, 0.76) 66.57%, #2563EB 98.63%)"
            opacity=".9"
            borderRadius="8px"
            zIndex="0"
          />
        </Box>
      </Link>
    </Box>
  );
}

export default memo(PostCard);
