import { Box, Image } from "@chakra-ui/react";
import { graphql } from "gatsby";
import React, { memo } from "react";
import { Container } from "../../Landing";
import Counter from "../../Landing/Counter/Counter";

const Customer = ({ data }) => {
  return (
    <Container>
      <Box
        as="h2"
        textStyle="titleLandingPage"
        textAlign="center"
        mb={{ base: "30px", sm: "56px" }}
      >
        {data?.title}
      </Box>

      <Box
        as="ul"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        listStyleType="none"
        bgColor="primary"
        color="white"
        p={{ base: "36px 15px", sm: "56px 40px" }}
        width="100%"
        sx={{
          li: {
            mb: { base: "16px", sm: "0" },
            width: { base: "100%", sm: "33.3333%" },
          },
          "li p": { mt: "8px" },
        }}
        mb={{ base: "50px", sm: "109px" }}
      >
        {data?.StatisticalInfo?.map((item) => (
          <Box as="li" display="flex" alignItems="center" key={item.id}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              w={{ base: "60px", md: "104px" }}
              h={{ base: "60px", md: "104px" }}
              borderRadius="50%"
              bgColor="white"
              mr="16px"
            >
              <Image
                src={item?.icon?.url}
                alt={item?.icon.alternativeText}
                borderRadius="50%"
                w="80%"
                h="80%"
              />
            </Box>
            <Box>
              <Box
                fontSize={{ base: "38px", md: "43" }}
                lineHeight="46px"
                fontWeight="500"
              >
                <Counter maxCount={item?.quantity} duration="10" label="+" />
              </Box>
              <Box as="p" fontSize="18" lineHeight="24px" fontWeight="500">
                {item?.content}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default memo(Customer);

export const query = graphql`
  fragment CUSTOMER_SECTION on STRAPI__COMPONENT_SECTIONS_CUSTOMERS {
    __typename
    ... on STRAPI__COMPONENT_SECTIONS_CUSTOMERS {
      id
      title
      description
      StatisticalInfo {
        id
        icon {
          url
          alternativeText
        }
        content
        quantity
      }
      patrons {
        name
        id
        icon {
          alternativeText
          url
        }
        url
      }
    }
  }
`;
