import { Box, Button, Input, Text, Textarea, useToast } from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";
import axios from "axios";
import { isEmpty } from "lodash";
import { defaultParams } from "../../../common/hooks/useGetUtm";

const FormSignup = ({ field, button, footerText }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [defaultValue, setDefaultValue] = useState({});

  useEffect(() => {
    if (!isEmpty(field)) {
      const defaultVal = field?.reduce((newObj, item) => {
        return {
          ...newObj,
          [item.name]: "",
        };
      }, {});
      setForm(defaultVal);
      setDefaultValue(defaultVal);
    }
  }, [field]);

  const handleSubmit = async (e) => {
    setLoading(true);
    const urlSearchParams = !!window.location.search
      ? new URLSearchParams(window.location.search)
      : "";
    const params = !!urlSearchParams
      ? Object.fromEntries(urlSearchParams.entries())
      : defaultParams;

    e.preventDefault();
    try {
      const _response = await axios.post(
        "https://cms.kaio.ai/api/signup-forms",
        {
          data: {
            ...form,
            ...params,
          },
        }
      );
      toast({
        title: "Thank you for your interest in Kaio. We will contact you soon.",
        status: "success",
        position: "top-right",
      });
    } catch (err) {
      toast({
        title: "Có lỗi xảy ra",
        status: "error",
        position: "top-right",
      });
    } finally {
      setLoading(false);
      setForm(defaultValue);
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderField = (item) => {
    switch (item.type) {
      case "text":
        return (
          <Input
            key={item.id}
            placeholder={item.placeholder}
            name={item.name}
            mb="1rem"
            isRequired={item.require}
            required={item.require}
            onChange={handleChange}
            value={form[item.name]}
          />
        );
      case "email":
        return (
          <Input
            key={item.id}
            placeholder={item.placeholder}
            name={item.name}
            isRequired={item.require}
            type="email"
            pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            mb="1rem"
            onChange={handleChange}
            value={form[item.name]}
          />
        );
      case "tel":
        return (
          <Input
            key={item.id}
            placeholder={item.placeholder}
            name={item.name}
            isRequired={item.require}
            type="tel"
            pattern="[0-9]{10}"
            mb="1rem"
            onChange={handleChange}
            value={form[item.name]}
          />
        );
      case "textarea":
        return (
          <Textarea
            placeholder={item.placeholder}
            name={item.name}
            rows={4}
            resize="none"
            bgColor="#fff"
            onChange={handleChange}
            value={form[item.name]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{ input: { bgColor: "#fff" } }}
      borderRadius="0.5rem"
      textAlign="center"
    >
      <Box as="form" color="black" onSubmit={handleSubmit}>
        {field?.map(renderField)}
        <Button
          type="submit"
          p="1rem"
          mt="2rem"
          w="full"
          color="#fff"
          bgColor="primary"
          isLoading={loading}
          _hover={{
            bgColor: "#538DFC",
            boxShadow: " 0px 4px 12px rgba(32, 101, 235, 0.3)",
          }}
          transition=".5s ease-in-out"
        >
          {button?.name}
        </Button>
        <Text mt="1rem" fontSize="0.75rem">
          {footerText}
        </Text>
      </Box>
    </Box>
  );
};

export default memo(FormSignup);
